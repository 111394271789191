import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import './index.css';
import { extractPoolNames, extractRoundNames, formatFixtureTable } from '../../Utilities';
import CustomModal from '../../components/modal';
import CustomButton from '../../components/custom_button';
import PoolTable from '../../components/pool_table';
import FixtureKoTable from '../../components/ko_fixtures';
import FixtureLeagueTable from '../../components/league_fixtures';

export default function Draws() {
  const [leaguePools, setLeaguePools] = useState<any>();
  const [koPools, setKoPools] = useState<any>();
  const [isPoolsLoading, setIsPoolsLoading] = useState<boolean>(false);
  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState<boolean>(false);
  const [isLeagueFixtureLoading, setIsLeagueFixtureLoading] = useState<boolean>(false);
  const [isKoFixtureLoading, setIsKoFixtureLoading] = useState<boolean>(false);
  const [isFirstTrigger, setIsFirstTrigger] = useState<boolean>(true);
  const [numOfPools, setNumOfPools] = useState<number>(1);
  const [leaguePoolNames, setLeaguePoolNames] = useState<Array<string>>([]);
  const [koPoolNames, setKoPoolNames] = useState<Array<string>>([]);
  const [leagueRoundNames, setLeagueRoundNames] = useState<Array<string>>([]);
  const [koRoundNames, setKoRoundNames] = useState<Array<string>>([]);
  const [leagueFixtureTable, setLeagueFixtureTable] = useState<any>();
  const [koFixtureTable, setKoFixtureTable] = useState<any>();
  const [fixtureLeagueApiResp, setFixtureLeagueApiResp] = useState<any>();
  const [fixtureKoApiResp, setFixtureKoApiResp] = useState<any>();
  const [poolInputData, setPoolInputData] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string>('');
  const [winnerName, setWinnerName] = useState<string>('');
  const [isExploding, setIsExploding] = React.useState(false);
  const fileInputRef: any = useRef(null);
  const [selectedOption, setSelectedOption] = useState('');

  const BASE_URL = process.env.REACT_APP_BASE_URL || "http://localhost:4000/"

  function getPoolData() {
    setKoPools(null);
    setLeaguePools(null);
    setLeagueFixtureTable(null);
    setKoFixtureTable(null);
    setIsCreateButtonDisabled(true)
    setIsPoolsLoading(true); // Set isLoading to true when making the API call
    axios.get(`${BASE_URL}api/create-pools`, { params: { numberOfPools: numOfPools, poolData: JSON.stringify(poolInputData) } })
      .then((response) => {
        setTimeout(() => {
          setKoPools(response.data)
          setLeaguePools(response.data)
          setIsPoolsLoading(false);
          setIsFirstTrigger(true)
          setIsCreateButtonDisabled(false)
        }, 1000)

      }).catch((error) => {
        console.error("Error fetching create-pools API:", error);
        // Handle the error, e.g., set an error state
      });
  }

  function handleOptionChange(event: any) {
    setSelectedOption(event.target.value);
  };

  function handleButtonClick() {
    if (selectedOption === 'Knockout') {
      getKoFixtures()
    } else if (selectedOption === 'League') {
      getLeagueFixtures()
    }
  };

  function getLeagueFixtures() {
    setIsLeagueFixtureLoading(true); // Set isLoading to true when making the API call
    axios.get(`${BASE_URL}api/create-league-fixtures`, { params: { pools: JSON.stringify(leaguePools) } })
      .then(async (response) => {
        setFixtureLeagueApiResp(response.data)
        setLeaguePoolNames(extractPoolNames(response.data));
        setLeagueRoundNames(extractRoundNames(response.data));
      });
  }

  function getKoFixtures() {
    setIsKoFixtureLoading(true); // Set isLoading to true when making the API call
    axios.get(`${BASE_URL}api/create-ko-fixtures`, { params: { pools: JSON.stringify(koPools), isFirstTime: isFirstTrigger } })
      .then(async (response) => {
        setFixtureKoApiResp(response.data)
        setIsFirstTrigger(false);
        setKoPoolNames(extractPoolNames(response.data));
        setKoRoundNames(extractRoundNames(response.data));

      });
  }

  useEffect(() => {
    if (leagueRoundNames != null && leagueRoundNames.length > 0)
      setLeagueFixtureTable(formatFixtureTable(fixtureLeagueApiResp, leaguePoolNames, leagueRoundNames));
    // eslint-disable-next-line
  }, [leagueRoundNames])


  useEffect(() => {
    if (koRoundNames != null && koRoundNames.length > 0)
      setKoFixtureTable(formatFixtureTable(fixtureKoApiResp, koPoolNames, koRoundNames));
    // eslint-disable-next-line
  }, [koRoundNames])

  useEffect(() => {
    if (leagueFixtureTable != null && leagueFixtureTable.length > 0)
      setTimeout(() => {
        setIsLeagueFixtureLoading(false);
      }, 2000)
  }, [leagueFixtureTable])

  useEffect(() => {
    if (koFixtureTable != null && koFixtureTable.length > 0)
      setTimeout(() => {
        setIsKoFixtureLoading(false);
      }, 2000)
  }, [koFixtureTable])

  useEffect(() => {
  }, [fileName])

  function openFileChooser() {
    fileInputRef.current.click();
  }

  function handleUpload(event: React.ChangeEvent<HTMLInputElement>) {
    let file: any = event.target.files && event.target.files[0];
    setFileName(file.name)
    const reader = new FileReader();
    reader.onload = function (event) {
      const fileData = event.target?.result as string;
      const lines = fileData.split('\n').map(line => line.trim());

      const data: any = lines
        .filter(line => line && line !== "playerName,jklc")
        .map(line => {
          const [playerName, jklc] = line.split(',');
          return { playerName, jklc };
        });
      setKoPools(null);
      setLeaguePools(null);
      setLeagueFixtureTable(null);
      setIsFirstTrigger(true)
      setKoFixtureTable(null);
      setPoolInputData(data)
      if (event.target && event.target instanceof HTMLInputElement) {
        event.target.value = '';
      }
    };
    reader.readAsText(file);
  }

  function setNewKoPoolTableValues(newPoolValue: any): void {
    if (koFixtureTable.length > 1) {
      const convertedObject: any = {};
      for (const obj of newPoolValue) {
        const poolKey = obj.pool;
        if (!(poolKey in convertedObject)) {
          convertedObject[poolKey] = [];
        }
        const playerName = obj.winner;
        const jklc = obj.winnerJklc;
        const playerObj = { playerName, jklc };
        convertedObject[poolKey].push(playerObj);
      }
      setIsExploding(false);
      setIsCreateButtonDisabled(false);
      setKoPools(convertedObject);
    } else {
      setIsCreateButtonDisabled(true);
      if (newPoolValue.length > 0) {
        setWinnerName(newPoolValue[0]?.winner || '');
        setIsExploding(true);
      } else {
        setWinnerName(''); // Reset winnerName if there is no winner
        setIsExploding(false);
      }
    }
  }

  function closeModal() {
    setIsExploding(false);
  }

  return (
    <div className='draws-container'>
      <CustomModal closeModal={closeModal} isExploding={isExploding} winnerName={winnerName} />
      <div className='button-container'>
        <label htmlFor="fileInput" className="fileInputButton">
          <input
            type="file"
            id="fileInput"
            accept=".csv"
            ref={fileInputRef}
            onChange={handleUpload}
            style={{ display: 'none' }} // Hide the input element
          />
        </label>
        <CustomButton onClick={openFileChooser} title={fileName ? fileName : "Upload Pool Data"} isDisabled={false} />
        <input type="number" className="input-field" onChange={(e) => {
          const value = parseInt(e.target.value);
          setNumOfPools(value);
        }} />
        <CustomButton onClick={getPoolData} title='Create Pools' isDisabled={false} />
        <div className='radio-options'>
          <label className='radio-option-container'>
            <input type="checkbox" name="options" id="league" value="League" checked={selectedOption === 'League'} onChange={handleOptionChange} />
            <div className="checkmark"></div>
            <div className="checkbox-text">League</div>
          </label>
          <label className='radio-option-container'>
            <input type="checkbox" name="options" id="league" value="Knockout" checked={selectedOption === 'Knockout'} onChange={handleOptionChange} />
            <div className="checkmark"></div>
            <div className="checkbox-text">Knockout</div>
          </label>
        </div>
        <CustomButton onClick={handleButtonClick} title='Create Fixtures' isDisabled={isCreateButtonDisabled} />
      </div>
      <br />
      {isPoolsLoading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
      <div className="pool-tables-container">
        {leaguePools && Object.keys(leaguePools).map((pool, index) => {
          return <div key={index}>
            <PoolTable poolName={pool.replace("_", " ")} tableData={leaguePools[pool]} />
          </div>
        })
        }
      </div>
      <br />
      <br />
      <br />
      {isLeagueFixtureLoading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
      {!isLeagueFixtureLoading && leagueFixtureTable && leaguePoolNames && <FixtureLeagueTable fixtureTable={leagueFixtureTable} pNames={leaguePoolNames} />}

      {isKoFixtureLoading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
      {!isKoFixtureLoading && koFixtureTable && koPoolNames && <FixtureKoTable fixtureTable={koFixtureTable} koPoolTable={setNewKoPoolTableValues} />}
      {/* <div className='button-container'>
          <CustomButton onClick={getKoFixtures} title='New Match Fixtures' isDisabled={isCreateButtonDisabled} />
          </div> */}
    </div >
  );
}
