import _ from 'lodash'

export function extractPoolNames(data: any) {
    let pn: Array<string> = Object.keys(data)
    const poolNames: Array<string> = [];
    for (let index = 0; index < pn.length; index++) {
        if (pn[index] === "Finals") {
            poolNames.push(pn[index])
        } else {
            let pnArr = pn[index].split("_");
            poolNames.push(pnArr[0] + "_" + pnArr[1]);
        }
    }
    return (_.uniq(poolNames))
}


export function extractRoundNames(data: any) {
    let rn: Array<string> = Object.keys(data)
    const roundNames: Array<string> = [];
    for (let index = 0; index < rn.length; index++) {
        if (rn[index] === "Finals") {
            roundNames.push(rn[index])
        } else {
            let rnArr = rn[index].split("_");
            roundNames.push(rnArr[2] + "_" + rnArr[3]);
        }
    }
    return (_.uniq(roundNames))
}



export function formatFixtureTable(data: any, pNames: Array<string>, rNames: Array<string>) {
    let fixturesNew: any = []
    for (let index = 0; index < Object.entries(data).length; index++) {
        const [roundName, matches] = Object.entries(data)[index];
        let poolIndex = _.findIndex(pNames, function (o) { return (roundName.split("_")[0] + "_" + roundName.split("_")[1]) === o });
        let roundIndex = _.findIndex(rNames, function (o) { return (roundName.split("_")[2] + "_" + roundName.split("_")[3]) === (o) });
        let poolName = pNames[poolIndex]
        let rName = rNames[roundIndex]
        fixturesNew.push({ poolName, rName, matches })
    }
    return (fixturesNew)
}

export function redirect(path: string) {
    window.location.href = path;
}


export const teams = ["Stallions FC", "Zarkava FC", "Falcons FC", "Spartans FC", "Panthers FC", "Strikers FC"]