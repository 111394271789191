import React from 'react'
import { redirect } from '../../Utilities'
import './index.css'
import CustomButton from '../../components/custom_button'

export default function Home() {
    return (
        <div className='home-container'>
            <CustomButton onClick={() => { redirect('/auction') }} title='Auctions' isDisabled={false} />
            <CustomButton onClick={() => { redirect('/draws') }} title='Draws' isDisabled={false} />
        </div>
    )
}
