// src/supabaseClient.ts
import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://tpeeuchpogimbhzhrtov.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InRwZWV1Y2hwb2dpbWJoemhydG92Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjQ5Mjg5OTAsImV4cCI6MjA0MDUwNDk5MH0.ItphRxnoQdxD3iTjFO6tQINnPLCJU5Bny9oPS9yDUH0';

// Create a single Supabase client instance
const supabase = createClient(supabaseUrl, supabaseKey);

export default supabase;
