import React from 'react';
import { Nav, Navbar, Container } from 'react-bootstrap';
import './index.css'
import { redirect } from '../../Utilities';

export default function CustomNavbar() {
  let location = window.location;
  return (
    <>
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand href="/"><img src="/assets/images/logo.png" alt='logo' /></Navbar.Brand>
          <Navbar.Toggle ria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="m-2" activeKey={location.pathname}>
              <Nav.Link href='/' >Home</Nav.Link>
              <Nav.Link href='/auction' >Auction</Nav.Link>
              <Nav.Link href='/draws' >Draws</Nav.Link>
              {/* <Nav.Link onClick={() => redirect('https://www.premieronetech.com/')} >PremierOne Tech</Nav.Link>
              <Nav.Link onClick={() => redirect('https://karim.premieronetech.com/')} >Dev Portfolio</Nav.Link> */}
            </Nav>
            {/* <Nav className="m-2" >
              <Nav.Link className='text-color-orange' href='mailto:info@premieronetech.com' >Mail Us</Nav.Link>
              <Nav.Link className='text-color-orange' href='tel:+919820690555 ' > Call Us</Nav.Link>
            </Nav> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
