import React from 'react';
interface Props {
    onClick: () => void
    title: string
    isDisabled: boolean
    minWidth?: string
    fontSize?: string
}

export default function CustomButton(props: Props) {
    return (
        <button style={{ minWidth: props.minWidth ? props.minWidth : '', fontSize: props.fontSize ? props.fontSize : '' }} id='btn' className="button" onClick={props.onClick} disabled={props.isDisabled}>
            <span>{props.title}</span>
        </button>
    );
}
