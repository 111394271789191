import React, { useCallback, useEffect, useState } from "react";
import * as XLSX from "xlsx";
import './index.css';
import { SelectionPanel } from "../../components/selection";
import CustomButton from "../../components/custom_button";
import { teams } from "../../Utilities";
import { debounce } from "lodash";
import supabase from "../../SupaBase";


interface DataRow {
    [key: string]: any;
}

interface AuctionProps {
    auctionData: DataRow[];
    onDataUpdate: (updatedData: DataRow[]) => void;
}

export default function Auction(props: AuctionProps) {
    const [data, setData] = useState<DataRow[]>([]);
    const [bibNumber, setBibNumber] = useState<any>();
    const [name, setName] = useState<any>();
    const [selectedPools, setSelectedPools] = useState<string[]>([]);
    const [filteredData, setFilteredData] = useState<DataRow[]>([]);
    const [teamsData, setTeamsData] = useState<Record<string, DataRow[]>>({});
    const [isManager, setIsManager] = useState<string | null>(null);

    useEffect(() => {
        const isManagerSession = sessionStorage.getItem("isManager");
        setIsManager(isManagerSession);
    }, []);

    useEffect(() => {
        setData(props.auctionData);
        setFilteredData(props.auctionData);
        updateTeamsData(props.auctionData);
    }, [props.auctionData]);

    useEffect(() => {
        const channel = supabase
            .channel('auctiondata_channel')
            .on('postgres_changes', { event: '*', schema: 'public', table: 'auctiondata' }, (payload) => {
                console.log('Real-time change payload received:', payload);
                applyChanges([payload]);
            })
            .subscribe();

        return () => {
            console.log('Unsubscribing from real-time updates.');
            supabase.removeChannel(channel);
        };
    }, []);

    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsBinaryString(file);
            reader.onload = async (e) => {
                const data = e.target?.result as string;
                const workbook = XLSX.read(data, { type: "binary" });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const parsedData: any[] = XLSX.utils.sheet_to_json(sheet);
                parsedData.forEach((item: any) => {
                    item['Bib Num'] = item[" Bib No."];
                    item['Base'] = item["BASE PRICE"];
                    item['Pool'] = item["POOL"];
                    item['Accquired Price'] = 0;
                    item['Selected Team'] = " ";
                    delete item[" Bib No."];
                    delete item["BASE PRICE"];
                    delete item["POOL"];
                    delete item["Missing Days"];
                    delete item["Position preferred"];
                    delete item["Would you be missing any matchdays?"];
                    delete item["WhatsApp Number"];
                    delete item["Alternate position preferred"];
                });
                await handleUpdate(parsedData);
            };
        }
    };

    const handleUpdate = async (newData: DataRow[]) => {
        console.log("Triggering data update in Auction with:", [...newData]);
        props.onDataUpdate([...newData]);
        debouncedUpdate([...newData]);
    };

    const debouncedUpdate = useCallback(
        debounce(async (updatedData: DataRow[]) => {
            setData(updatedData);
            setFilteredData(updatedData);
            updateTeamsData(updatedData);

            // Ensure 'Bib Num' is correctly referenced and matches the unique constraint
            const { error } = await supabase
                .from('auctiondata')
                .upsert(updatedData, { onConflict: 'Bib Num' });

            if (error) {
                console.error("Error updating data:", error.details);
            } else {
                console.log("Data updated in Supabase successfully");
            }
        }, 1000),
        []
    );

    const filterDataBySelectedPools = () => {
        if (selectedPools.length === 0) {
            handleUpdate(data);
        } else {
            const filteredData = data.filter((row) => selectedPools.includes(row['Pool']));
            setFilteredData(filteredData);
        }
    };

    const filterDataByName = () => {
        if (!name) {
            handleUpdate(data);
        } else {
            const filteredData = data.filter((row) => row['Name'].includes(name));
            setFilteredData(filteredData);
        }
    };

    const filterDataByBib = () => {
        if (!bibNumber) {
            handleUpdate(data);
        } else {
            const filteredData = data.filter((row) => row['Bib Num'] === parseInt(bibNumber));
            setFilteredData(filteredData);
        }
    };

    const handleTeamDropdownChange = async (row: DataRow, selectedValue: string) => {
        const updatedData = [...data];
        const mainDataIndex = updatedData.findIndex((item) => item['Bib Num'] === row['Bib Num']);

        if (mainDataIndex !== -1) {
            // Row exists, update the 'Selected Team'
            updatedData[mainDataIndex]['Selected Team'] = selectedValue;
            await handleUpdate(updatedData);
        } else {
            // Row doesn't exist, log an error or handle it accordingly
            console.error("Row with Bib Num not found, cannot update 'Selected Team'");
        }
    };


    const handleAccquiredCost = async (row: DataRow, selectedValue: string) => {
        const updatedData = [...data];
        const mainDataIndex = updatedData.findIndex((item) => item['Bib Num'] === row['Bib Num']);

        if (mainDataIndex !== -1) {
            // Update the specific row
            updatedData[mainDataIndex]['Accquired Price'] = selectedValue;
            await handleUpdate(updatedData);
        } else {
            console.error("Row with Bib Num not found");
        }
    };


    const getTeamData = (team: string) => {
        return teamsData[team] || [];
    };

    const updateTeamsData = (data: DataRow[]) => {
        const teamGroups = teams.reduce((acc, team) => {
            acc[team] = data.filter((item) => item['Selected Team'] === team);
            return acc;
        }, {} as Record<string, DataRow[]>);
        setTeamsData(teamGroups);
    };

    const columns = [
        { key: 'Bib Num', label: 'Bib Num' },
        { key: 'Name', label: 'Name' },
        { key: 'Base', label: 'Base' },
        { key: 'Pool', label: 'Pool' },
        { key: 'Accquired Price', label: 'Accquired Price' },
        { key: 'Selected Team', label: 'Team' }
    ];

    const applyChanges = (changes: any[]) => {
        setData(prevData => {
            const newData = [...prevData];
            changes.forEach(change => {
                const { eventType, new: newDataItem, old: oldDataItem } = change;
                if (eventType === 'INSERT') {
                    newData.push(newDataItem);
                } else if (eventType === 'UPDATE') {
                    const index = newData.findIndex(item => item['Bib Num'] === newDataItem['Bib Num']);
                    if (index !== -1) {
                        newData[index] = newDataItem;
                    }
                } else if (eventType === 'DELETE') {
                    const index = newData.findIndex(item => item['Bib Num'] === oldDataItem['Bib Num']);
                    if (index !== -1) {
                        newData.splice(index, 1);
                    }
                }
            });
            updateTeamsData(newData);
            return newData;
        });
    };

    return (
        <div className="App">
            {isManager === "false" ? (
                <div className="left-panel">
                    <input
                        type="file"
                        accept=".xlsx, .xls"
                        onChange={handleFileUpload}
                    />
                    <div style={{ display: 'flex', maxHeight: '1%', flexWrap: 'wrap' }}>
                        <div style={{ display: 'flex', alignItems: 'center', border: '1px solid black', margin: '0.5rem', padding: '0.5rem' }}>
                            {["A", "B", "C"].map((pool, index) => (
                                <label key={index} style={{ padding: '0.5rem' }}>
                                    <input
                                        type="checkbox"
                                        value={pool}
                                        checked={selectedPools.includes(pool)}
                                        onChange={(e) => {
                                            const pool = e.target.value;
                                            setSelectedPools((prevSelectedPools) =>
                                                prevSelectedPools.includes(pool)
                                                    ? prevSelectedPools.filter((p) => p !== pool)
                                                    : [...prevSelectedPools, pool]
                                            );
                                        }}
                                    />
                                    {pool}
                                </label>
                            ))}
                            <CustomButton fontSize="0.5rem" minWidth="0" isDisabled={false} title="Filter by Pool" onClick={filterDataBySelectedPools} />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid black', margin: '0.5rem', padding: '0.5rem' }}>
                            <input type="number" onChange={(event) => setBibNumber(event.target.value)} />
                            <CustomButton fontSize="0.5rem" minWidth="0" isDisabled={false} title="Filter by Bib" onClick={filterDataByBib} />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid black', margin: '0.5rem', padding: '0.5rem' }}>
                            <input type="text" onChange={(event) => setName(event.target.value)} />
                            <CustomButton fontSize="0.5rem" minWidth="0" isDisabled={false} title="Filter by Name" onClick={filterDataByName} />
                        </div>
                    </div>
                    {filteredData.length > 0 ? (
                        <table className="table">
                            <thead>
                                <tr>
                                    {columns.map((column, index) => (
                                        <th key={index}>{column.label}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData.map((row, rowIndex) => (
                                    <tr key={rowIndex}>
                                        {columns.map((column, colIndex) => {
                                            const cellValue = row[column.key];
                                            return (
                                                <td key={colIndex}>
                                                    {column.key === 'Accquired Price' ? (
                                                        <input
                                                            className='auction-input'
                                                            type="number"
                                                            value={cellValue}
                                                            onChange={(e) => handleAccquiredCost(row, e.target.value)}
                                                        />
                                                    ) : column.key === 'Selected Team' ? (
                                                        <select
                                                            className='auction-input'
                                                            value={cellValue || ""}
                                                            onChange={(e) => handleTeamDropdownChange(row, e.target.value)}
                                                        >
                                                            <option value="">Select Team</option>
                                                            {teams.map((team, teamIndex) => (
                                                                <option key={teamIndex} value={team}>
                                                                    {team}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    ) : (
                                                        cellValue
                                                    )}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p>No data available</p>
                    )}
                </div>
            ) : null}
            <div className="right-panel">
                {teams.map((team) => (
                    <SelectionPanel
                        key={team}
                        teamName={team}
                        selectedNames={getTeamData(team)}
                    />
                ))}
            </div>
        </div>
    );
}
