import React from 'react';
import './index.css';

interface Props {
    fixtureTable: any;
    pNames: any;
}

export default function FixtureLeagueTable(props: Props) {
    return (
        <div className="fixture-table">
            <h2>League Fixture Table</h2>
            <div className="pool">
                <div className="pool-section">
                    {props.pNames &&
                        props.pNames.map((poolN: string, pindex: number) => (
                            <div key={pindex} className="pool-sub-section">
                                <p>
                                    <b>{poolN.replaceAll("_"," ")}</b>
                                </p>
                                <div className="league-fixture-container">
                                    {props.fixtureTable &&
                                        props.fixtureTable.map(
                                            ({ poolName, rName, matches }: any, index: number) => {
                                                if (poolN === poolName) {
                                                    return <div key={index} style={{ padding: '1%' }}>
                                                        {rName && <span>{rName.replaceAll("_"," ")}</span>}
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th>Player 1</th>
                                                                    <th>Player 2</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {matches.map((match: any, index: number) => (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            {match.player1 && match.player1.playerName}{' '}
                                                                            {match.player1 && match.player1.jklc && `(${match.player1.jklc})`}
                                                                        </td>
                                                                        <td>
                                                                            {match.player2 && match.player2.playerName}{' '}
                                                                            {match.player2 && match.player2.jklc && `(${match.player2.jklc})`}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                } else {
                                                    return null;
                                                }
                                            }
                                        )}
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
}
