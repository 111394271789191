// PasswordPopup.tsx
import React, { useState } from 'react';
import './index.css'; // Create a CSS file for styling

interface PasswordPopupProps {
  onSubmit: (isManager: boolean) => void;
}

const PasswordPopup: React.FC<PasswordPopupProps> = ({ onSubmit }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    // Replace 'yourPassword' with the actual password
    if (password === 'AdminFsl2024') {
        sessionStorage.setItem("isManager", "false")
      onSubmit(true); // User is a manager
    } 
    if (password === 'Manager') {
        sessionStorage.setItem("isManager", "true")
        onSubmit(false); 
      }
  };

  return (
    <div className="password-popup">
      <form onSubmit={handleSubmit}>
        <h2>Enter Password</h2>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Submit</button>
        {error && <p className="error">{error}</p>}
      </form>
    </div>
  );
};

export default PasswordPopup;
