import React, { useEffect, useState } from 'react';
import './index.css';
import _ from 'lodash';

interface Match {
    player1: {
        playerName: string;
        jklc: string;
    };
    player2: {
        playerName: string;
        jklc: string;
    };
}

interface Fixture {
    poolName: string;
    rName: string;
    matches: Match[];
}

interface Props {
    fixtureTable: Fixture[];
    koPoolTable: (newPoolValue: any) => void;
}

export default function FixtureKoTable(props: Props) {
    const [round] = useState<number>(1);
    const [winners, setWinners] = useState<({ round: string; pool: string; winner: string, matchIndex: number, winnerJklc: string }[])>([]);

    const handleWinnerSelection = (poolName: string, matchIndex: number, winner: string, winnerJklc: string) => {
        const updatedWinners = _.cloneDeep(winners);
        const poolWinners = updatedWinners.find(pool => pool.pool === poolName && matchIndex === pool.matchIndex);
        if (poolWinners?.matchIndex === matchIndex) {
            // Update the existing pool winner and match index
            poolWinners.winner = winner;
            poolWinners.winnerJklc = winnerJklc;
            poolWinners.matchIndex = matchIndex;
        } else {
            // Adding new winner and match index
            updatedWinners.push({
                round: `round_${round}`,
                pool: poolName,
                winner: winner,
                winnerJklc: winnerJklc,
                matchIndex: matchIndex,
            });
        }
        setWinners(updatedWinners);

    };


    useEffect(() => {
        let updatedWinners = winners;

        props.fixtureTable.forEach((poolFixtures) => {
            let poolName = poolFixtures.poolName;

            // Find all Bye matches in the poolFixtures
            let byeMatches = poolFixtures.matches.filter(
                (match) => match.player1?.playerName === "Bye" || match.player2?.playerName === "Bye"
            );

            // Process each Bye match
            byeMatches.forEach((byeMatch, byeMatchIndex) => {
                if (byeMatch?.player1.playerName === "Bye") {
                    updatedWinners.push({
                        round: `round_${round}`,
                        pool: poolName,
                        winner: byeMatch?.player2.playerName,
                        winnerJklc: byeMatch?.player2.jklc,
                        matchIndex: byeMatchIndex,
                    });
                }
                if (byeMatch?.player2.playerName === "Bye") {
                    updatedWinners.push({
                        round: `round_${round}`,
                        pool: poolName,
                        winner: byeMatch?.player1.playerName,
                        winnerJklc: byeMatch?.player1.jklc,
                        matchIndex: byeMatchIndex,
                    });
                }
            });
        });

        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        props.koPoolTable(winners)
    }, [props, winners])


    const renderPool = ({ poolName, matches }: Fixture) => (
        <div key={poolName} className="ko-pool-container">
            <div className="pool">
                <div className="pool-section">
                    <div className="ko-fixture-container">
                        <h3>Match Table {poolName ? _.startCase(_.lowerCase(poolName.replace("_", " "))) : "Finals"}</h3>
                        <table>
                            <thead>
                                <tr>
                                    <th>Player 1</th>
                                    <th>Player 2</th>
                                </tr>
                            </thead>
                            <tbody>
                                {matches.map(({ player1, player2 }: Match, matchIndex: number) => {
                                    const player1Name = player1.playerName;
                                    const player2Name = player2.playerName;
                                    const isPlayer1Winner = _.find(winners, function (o) { return o.winner === player1Name });
                                    const isPlayer2Winner = _.find(winners, function (o) { return o.winner === player2Name });
                                    return (
                                        <tr key={matchIndex}>
                                            <td
                                                onClick={() => handleWinnerSelection(poolName, matchIndex, player1Name, player1.jklc)}
                                                className={`player-cell ${isPlayer1Winner ? 'winner' : ''} ${player1Name === "Bye" ? 'red-bye' : ''}`}
                                            >
                                                {player1Name} {player1.jklc && `(${player1.jklc})`}
                                            </td>
                                            <td
                                                onClick={() => handleWinnerSelection(poolName, matchIndex, player2Name, player2.jklc)}
                                                className={`player-cell ${isPlayer2Winner ? 'winner' : ''} ${player2Name === "Bye" ? 'red-bye' : ''}`}
                                            >
                                                {player2Name} {player2.jklc && `(${player2.jklc})`}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="match-winners-table">
                <h3>Match Winners {poolName ? _.startCase(_.lowerCase(poolName.replace("_", " "))) : "Finals"}</h3>
                <table>
                    <thead>
                        <tr>
                            <th>Match Number</th>
                            <th>Winner</th>
                            <th>JKLC</th>
                        </tr>
                    </thead>
                    <tbody>
                        {_.sortBy(winners, [function (o) { return o.matchIndex; }]).map((winner, index) => {
                            if (winner.pool === poolName) {
                                return <tr key={index}>
                                    <td>{winner.matchIndex + 1}</td>
                                    <td>{winner.winner}</td>
                                    <td>{winner.winnerJklc}</td>
                                </tr>
                            } else {
                                return null
                            }
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );


    return (
        <div className="fixture-table">
            <h2>Knockout Fixture Table</h2>
            <div className="pools-container">
                {props.fixtureTable.slice(0, props.fixtureTable.length / 2).map(renderPool)}
                {props.fixtureTable.slice(props.fixtureTable.length / 2).map(renderPool)}
            </div>
        </div>
    );

}
