import React, { useState, useEffect } from 'react';
import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/home';
import Draws from './pages/draws';
import Auction from './pages/auction';
import CustomNavbar from './components/custom_navbar';
import supabase from './SupaBase';
import PasswordPopup from './components/password_popup';

interface DataRow {
  [key: string]: any;
}

function App() {
  const location = useLocation();
  const [auctionData, setAuctionData] = useState<DataRow[]>([]);
  const [isManager, setIsManager] = useState<any>();
  const [showPopup, setShowPopup] = useState(true);

  const sortByPool = (data: DataRow[]) => {
    const poolOrder = { 'A': 1, 'B': 2, 'C': 3 };
    return data.sort((a, b) => {
      const poolA = a['Pool'] as keyof typeof poolOrder;
      const poolB = b['Pool'] as keyof typeof poolOrder;
      return (poolOrder[poolA] || 0) - (poolOrder[poolB] || 0);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);


  useEffect(() => {
    let isManager: any = sessionStorage.getItem("isManager");
    setIsManager(isManager)
  })

  const fetchData = async () => {
    const { data: fetchedData, error } = await supabase
      .from('auctiondata')
      .select('*');

    if (error) {
      console.error("Error fetching data:", error);
      return;
    }

    if (fetchedData.length > 0) {
      const sortedData = sortByPool(fetchedData);
      setAuctionData(sortedData);
    } else {
      console.log("No data found in Supabase.");
    }
  };

  const handleAuctionDataUpdate = (newData: DataRow[]) => {
    const sortedData = sortByPool(newData);
    setAuctionData(sortedData);
  };

  const handlePopupSubmit = () => {
    setShowPopup(false);
  };

  return (
    <>
      {isManager == "false" && <CustomNavbar />}
      {showPopup && sessionStorage.getItem("isManager") === null && <PasswordPopup onSubmit={handlePopupSubmit} />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/draws" element={<Draws />} />
        <Route path="/auction" element={<Auction auctionData={auctionData} onDataUpdate={handleAuctionDataUpdate} />} />
      </Routes>
    </>
  );
}

export default App;
