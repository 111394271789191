import React from 'react';
import { Modal } from 'react-bootstrap'; // Assuming you are using react-bootstrap
import ConfettiExplosion from 'react-confetti-explosion';

interface CustomModalProps {
  isExploding: boolean;
  closeModal: () => void;
  winnerName: string;
}

const CustomModal: React.FC<CustomModalProps> = ({ isExploding, closeModal, winnerName }) => {
  return (
    <Modal show={isExploding} onHide={closeModal} centered style={{backgroundColor:'transparent !important'}}>
      <ConfettiExplosion
        className='confetti-container'
        force={0.8}
        duration={3000}
        particleCount={300}
        width={1600}
      />
      <Modal.Body className="winner-modal">
        <p className="winner-text">Winner: {winnerName}</p>
      </Modal.Body>
    </Modal>
  );
};

export default CustomModal;
