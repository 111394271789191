import React, { useState, useEffect, Fragment } from 'react';
import './index.css';

interface ISelectionProps {
    selectedNames: any[],
    teamName: string
}

export function SelectionPanel(props: ISelectionProps) {
    const [data, setData] = useState<any[]>([]);
    const [totalSpent, setTotalSpent] = useState<number>(0);
    const [remainingBudget, setRemainingBudget] = useState<number>(13500);

    useEffect(() => {
        setData(props.selectedNames);
    }, [props.selectedNames]);

    const calculateAverage = (pool: string) => {
        const poolData = data.filter((playerDetails) => playerDetails['Pool'] === pool);
        const totalPrices = poolData.reduce((acc, player) => acc + parseFloat(player['Accquired Price']), 0);
        return poolData.length === 0 ? 0 : (totalPrices / poolData.length).toFixed(2);
    };

    const calculateTotalSpent = () => {
        return data.reduce((acc, player) => acc + parseFloat(player['Accquired Price']), 0);
    };

    const calculateRemainingBudget = () => {
        return 13500 - totalSpent;
    };

    const calculateAvgSpend = () => {
        let maxPlayers = 15;
        let avgSpendCapacity = (remainingBudget / (maxPlayers - data.length)).toFixed(2);
        return avgSpendCapacity;
    };

    useEffect(() => {
        setTotalSpent(calculateTotalSpent());
        setRemainingBudget(calculateRemainingBudget());
    }, [data]);

    return (
        <div className="selection-panel">
            {data ? (
                <div className="selected-team">
                    <div style={{ "display": "flex", alignItems: 'center', justifyContent: 'space-around', height: "2rem", objectFit: 'contain' }}>
                        <h2>{props.teamName}</h2>
                        <img style={{height: "5rem", objectFit: 'contain' }} src={`/assets/images/team_logos/${props.teamName.split(" ")[0].toLowerCase()}.png`} alt={props.teamName} />
                    </div>
                    <h3>Players Names:</h3>
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Pool</th>
                                <th>Base Price</th>
                                <th>Accquired Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((playerDetails: any, index: number) => (
                                <Fragment key={index}>
                                    <tr>
                                        <td>{playerDetails['Name']}</td>
                                        <td>{playerDetails['Pool']}</td>
                                        <td>{playerDetails['Base']}</td>
                                        <td>{playerDetails['Accquired Price']}</td>
                                    </tr>
                                </Fragment>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={3}>Average Pool A:</td>
                                <td>{calculateAverage('A')}</td>
                            </tr>
                            <tr>
                                <td colSpan={3}>Average Pool B:</td>
                                <td>{calculateAverage("B")}</td>
                            </tr>
                            <tr>
                                <td colSpan={3}>Average Pool C:</td>
                                <td>{calculateAverage("C")}</td>
                            </tr>
                            <tr>
                                <td colSpan={3}>Total Spent</td>
                                <td>{calculateTotalSpent()}</td>
                            </tr>
                            <tr>
                                <td colSpan={3}>Remaining Budget</td>
                                <td>{calculateRemainingBudget()}</td>
                            </tr>
                            <tr>
                                <td colSpan={3}>Average Spend Capacity</td>
                                <td>{calculateAvgSpend()}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            ) : (
                <p>No data available</p>
            )}
        </div>
    );
}
