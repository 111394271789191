import React from 'react';
import './index.css';

interface Props {
    poolName: string;
    tableData: Array<{ playerName: string, jklc: string }>;
}

export default function PoolTable(props: Props) {
    return (
        <table className="pool-table">
            <thead>
                <tr>
                    <th colSpan={1}>{props.poolName}</th>
                </tr>
            </thead>
            <tbody>
                {props.tableData.map((matchDetails, index) => {
                    return <tr key={index}>
                        <td>{matchDetails.playerName} ({matchDetails.jklc})</td>
                    </tr>
                })}
            </tbody>
        </table>
    );
}
